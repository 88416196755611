import { assignKeyIds } from "components/collectionsForm/collectionLongForm/widgets";
import Sample, { LabelMap } from "./Sample";
import {
  IConnection,
  IFormProps,
  IProjectProps,
  INode,
  ICollection,
  NodeRequest,
  IProjectConnection,
  IFormSchemaResponse,
  ICreateCollectionResponse,
  normalizeDefinitions,
} from "./types";
import { JSONSchema7 } from "json-schema";

type Method = "GET" | "POST" | "DELETE" | "PUT";

class CartaObjectStoreException extends Error {
  constructor(public host: string, public url: string, public method: Method, public response: Response, public body?: any) {
    super("An error occurred with the Carta Object Storage API");
  }
}

export interface NodesList {
  nextPageToken: string;
  nodes: INode[];
}

export default class CartaObjectStorage {
  public constructor(private host: string, private token: string, hosted: boolean = false) {
    if (hosted) {
      this.host = `${this.host}/service/carta/object-storage`;
    }

    if (!this.token.startsWith("Bearer ")) {
      this.token = `Bearer ${this.token}`;
    }
  }

  private async cartaObjectRequest(method: Method, url: string, body?: Object): Promise<Response> {
    const init = { method } as RequestInit;

    init.headers = new Headers(init.headers);
    init.headers.set("Authorization", this.token);

    if (typeof body !== "undefined") {
      init.headers.set("Content-Type", `application/json`);
      init.body = JSON.stringify(body);
    }

    const full_url = `${this.host}${url}`;

    const response = await fetch(full_url, init);

    if (!response.ok) {
      throw new CartaObjectStoreException(this.host, url, method, response, body);
    }

    return response;
  }

  // V2 Routes
  async listNodes(connection: NodeRequest, node: INode): Promise<NodesList> {
    const response = await this.cartaObjectRequest("POST", "/api/v2/list-nodes", {
      ...connection,
      node: node,
    });

    return (await response.json()) as NodesList;
  }

  // V1 Routes
  async listProjects(): Promise<IProjectProps[]> {
    const response = await this.cartaObjectRequest("POST", "/project-api/v1/list-projects");

    return (await response.json()) as IProjectProps[];
  }

  async listSchemas(project: IProjectProps): Promise<IFormProps[]> {
    const response = await this.cartaObjectRequest("POST", "/project-api/v1/list-schemas", project);

    return (await response.json()) as IFormProps[];
  }

  async listConnections(project: IProjectProps): Promise<IConnection[]> {
    const response = await this.cartaObjectRequest("POST", "/project-api/v1/list-connections", project);

    return (await response.json()) as IConnection[];
  }

  async getCollection(project: ICollection): Promise<string> {
    const response = await this.cartaObjectRequest("POST", "/project-api/v1/get-collection", project);

    let blob = await response.blob();
    blob = blob.slice(0, blob.size, "text/html");
    return URL.createObjectURL(blob);
  }

  async getFormSchema(project: ICollection): Promise<IFormSchemaResponse> {
    const response = await this.cartaObjectRequest("POST", "/project-api/v1/form-schema", project);
    const schemaResponse = (await response.json()) as IFormSchemaResponse;

    normalizeDefinitions(schemaResponse.schema as JSONSchema7);

    // This is needed for properly rendering widgets
    assignKeyIds(schemaResponse.schema as JSONSchema7, schemaResponse.key_info);

    schemaResponse.primaryKeys.forEach(pk=>{
      pk.created = new Date(pk.created);
    })

    return schemaResponse
  }

  async getSamples(_project: IProjectConnection): Promise<LabelMap> {
    const response = await this.cartaObjectRequest(
      "GET",
      `/project-api/v1/samples/${_project.projectId}/${_project.connectionId}`
    );
    return Sample.fromSampleList(await response.json());
  }

  async createCollection(payload: {
    projectId: string;
    key: object;
    formData: object;
  }): Promise<ICreateCollectionResponse[]> {
    const response = await this.cartaObjectRequest("POST", "/project-api/v1/create-collection", payload);
    return response.json();
  }

  

  async saveCollection(payload: {
    projectId: string;
    connectionId: string;
    key: object;
    formData: any;
    nativeId: any;
  }): Promise<any> {
    const response = await this.cartaObjectRequest("POST", "/project-api/v1/save-collection", {
      projectId: payload.projectId,
      connectionId: payload.connectionId,
      key: payload.key,
      formData: payload.formData,
      node: {nativeId: payload.nativeId}
    });
    return response.json();
  }
}
