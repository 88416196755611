import { FormData, ISavedForm, NativeId } from "./types";

export enum FormStatus {
    New,
    Modified,
    Saved
}

export interface IMonitorForm extends ISavedForm {
    status: FormStatus;
    isError: boolean;
}

export function wrapExistingForm(data: FormData | undefined, native: NativeId | undefined): IMonitorForm{
    return{
        data: data,
        nativeId: native,
        status: FormStatus.Saved,
        isError: false
    }
}

export function makeCloneForm(data: IMonitorForm): IMonitorForm{
    return{
        data: JSON.parse(JSON.stringify(data.data)),
        nativeId: undefined,
        status: FormStatus.New,
        isError: false
    }
}

export function makeBlankForm(): IMonitorForm {
    return {
        data: undefined,
        nativeId: undefined,
        status: FormStatus.New,
        isError: false
    }
}