import { FunctionComponent } from "react";
import { FormStatus } from "data/formStatus";
import { CheckCircle, Circle, RadioButtonUnchecked } from "@mui/icons-material";


interface FormStatusIconProps{
    status: FormStatus;
    isError: boolean;
}

export const FormStatusIcon: FunctionComponent<FormStatusIconProps> = ({
    status,
    isError
}) => {

    switch(status){
        case FormStatus.New:
            return (
                <RadioButtonUnchecked color={isError ? "error" : "info"} titleAccess="New"/>
            )
        case FormStatus.Modified:
            return (
                <Circle color={isError ? "error" : "warning"} titleAccess="Modified"/>
            )
        case FormStatus.Saved:
            return (
                <CheckCircle color="success" titleAccess="Saved"/>
            )
    }
};