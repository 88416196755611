import { createContext, FunctionComponent, useEffect, useState } from "react";
import CollectionLongForm from "./collectionLongForm/CollectionLongForm";
import CollectionTableForm from "./collectionTableForm/CollectionTableForm";
import { JSONSchema7 } from "json-schema";
import { IFormSchemaResponse, NativeId, PrimaryKeyWithDate } from "data/types";
import { Alert, AlertColor, Button, IconButton, Snackbar, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { FormStatus, IMonitorForm, makeBlankForm, makeCloneForm, wrapExistingForm } from "data/formStatus";
import useCartaObjectStorage from "hooks/useCartaObjectStorage";
import CartaObjectStorage from "data/CartaObjectStorage";
import { FormStatusIcon } from "./collectionLongForm/components/FormStatusIcon";

export {CollectionLongForm, CollectionTableForm};

interface FormEntryContextProps {
    primaryKeys: PrimaryKeyWithDate[];
}


interface FormSaveNotification{
    severity: AlertColor;
    message: string;
  }
  
  
export const FormEntryContext = createContext<FormEntryContextProps | null>(null);
  
const formLimit = 5;


async function saveForms(cos: CartaObjectStorage, forms: IMonitorForm[], schemaNative: NativeId, projectId: string, connectionId: string){
  
    const saveRequests = forms.map(async (form, index)=>{
      try{
        if(!form.data){
          console.error(`Nothing to save at form ${index}!`)
          return false;
        }
  
        if(form.nativeId){
          await cos.saveCollection({
            projectId, connectionId, formData: form.data, nativeId: form.nativeId, key: schemaNative
          })
        }else{
          const collection = await cos.createCollection({
            projectId, key: schemaNative, formData: form.data
          })
  
          form.nativeId = collection[0].nativeId
        }
        form.status = FormStatus.Saved;
        return true;
      }catch(e){
        console.error(`Error saving form ${index}`, e)
        form.isError = true;
        return false;
      }
    })
  
    return await Promise.all(saveRequests);
  }

interface CollectionFormProps {
    projectId: string;
    schema?: IFormSchemaResponse | null;
    connectionId: string;
  }

export const CollectionForm: FunctionComponent<CollectionFormProps> = ({
    schema,
    projectId,
    connectionId
})=>{
    const [alertStatus, setAlertStatus] = useState<FormSaveNotification | null>(null);
    const [viewMode, setViewMode] = useState<"longForm" | "table">("longForm");
    
    const [forms, setForms] = useState<IMonitorForm[]>([]);

    useEffect(()=>{
        if(schema){
            setForms(schema.form_data?.map(form=>wrapExistingForm(form.data, form.nativeId)) ?? [makeBlankForm()]); // Initialize with one empty form
        }else{
            setForms([])
        }
    }, [schema])
    
  const cartaObjectStorage = useCartaObjectStorage();

  const closeFormSaveNotification = ()=>{
    setAlertStatus(null);
  }

  
  const handleFormAddition = () => {
    if (forms.length < formLimit) {
      setForms([...forms, makeBlankForm()]);
    }
  };

  const handleFormDuplication = (index: number) => {
    
    if (forms.length < formLimit) {
      const formToDuplicate = forms[index];
      setForms([...forms, makeCloneForm(formToDuplicate)]);
    }
  };

  const handleFormRemoval = (index: number) => {
    const updatedForms = forms.filter((_, i) => i !== index);
    setForms(updatedForms);
  };

  const handleFormChange = (index: number, updatedData: any, hasError: boolean) => {
    const updatedForms = [...forms];
    const updatedForm = updatedForms[index];
    if(updatedForm.status === FormStatus.Saved && typeof updatedForm.data !== 'undefined'){
      updatedForm.status = FormStatus.Modified;
    }
    updatedForm.isError = hasError;
    updatedForm.data = updatedData;
    setForms(updatedForms);
  };

  const handleSave = async () => {
    try {
      const _cartaObjectStorage = await cartaObjectStorage;
      if (!schema) {
        throw new Error("Attempting to save when no schema defined");
      }
      setAlertStatus({
        severity: "info",
        message: "Saving forms..."
      });
      const schemaKey = { id: schema.schema_key.id, version: schema.schema_key.version }

      const saveSuccess = await saveForms(_cartaObjectStorage, forms, schemaKey, projectId, connectionId);
      setForms([...forms]);

      let successes: number = 0;
      for(let i = 0; i < saveSuccess.length; i++){
        successes += saveSuccess[i] ? 1 : 0;
      }

      if(successes === saveSuccess.length){
        setAlertStatus({
          severity: "success",
          message: "All forms saved successfully"
        });
      }else if(successes === 0){
        setAlertStatus({
          severity: "error",
          message: "Failed to save forms"
        });
      }else{
        setAlertStatus({
          severity: "warning",
          message: `${successes}/${saveSuccess.length} forms saved successfully`
        });
      }

    } catch (error) {
      console.error("Error saving forms:", error);
      setAlertStatus({
        severity: "error",
        message: "An error occurred while saving the forms. Please try again."
      });
    }
  };

  const handleViewToggle = (event: React.MouseEvent<HTMLElement>, newViewMode: "longForm" | "table" | null) => {
    if (newViewMode) {
      setViewMode(newViewMode);
    }
  };

  if(!schema){
    return <>Loading...</>
  }

    return (
        <>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewToggle}
            aria-label="View mode"
            style={{ marginBottom: "1rem" }}>
            <ToggleButton value="longForm" aria-label="Long form view">
              Long Form View
            </ToggleButton>
            <ToggleButton value="table" aria-label="Table view">
              Table Entry View
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton
            aria-label="add blank form"
            onClick={handleFormAddition}
            style={{ backgroundColor: "#28a745", color: "white", marginLeft: "1rem" }}>
            <AddIcon />
          </IconButton>
        </div>
        <FormEntryContext.Provider value={schema ? { primaryKeys: schema.primaryKeys } : null}>
          {/* Render either the long form view or the table view */}
          {viewMode === "longForm"
            ? schema &&
              forms.map((form, index) => (
                <Accordion key={index} style={{ marginBottom: "1rem" }} defaultExpanded={index === 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}>
                    <Typography variant="h6" style={{display: 'flex', alignItems: 'center', gap: 2}}> 
                      <FormStatusIcon status={form.status} isError={form.isError}/>
                      <span>Form {index + 1}</span>
                    </Typography>
                    <div style={{ marginLeft: "auto" }}>
                      <IconButton
                        aria-label="duplicate form"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleFormDuplication(index)
                        }}
                        color="primary">
                        <ContentCopyIcon />
                      </IconButton>
                      <IconButton aria-label="remove form" onClick={(event) => {
                          event.stopPropagation();
                          handleFormRemoval(index);
                          }}
                          color="secondary">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CollectionLongForm
                      formData={form}
                      schema={schema.schema as JSONSchema7}
                      onChange={(data, hasError) => handleFormChange(index, data, hasError)}
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            : schema && (
                <CollectionTableForm
                  forms={forms}
                  schema={schema.schema}
                  onChange={(index, updatedForm, hasError) => handleFormChange(index, updatedForm, hasError)}
                  onDuplicate={handleFormDuplication}
                  onRemove={handleFormRemoval}
                />
              )}
        </FormEntryContext.Provider>

        <Button
          onClick={handleSave}
          style={{ marginTop: "1rem", backgroundColor: "#28a745", color: "#fff" }}
          disabled={forms.length === 0}>
          Save Forms
        </Button>
        <Snackbar open={!!alertStatus} autoHideDuration={6000} onClose={closeFormSaveNotification}>
          <Alert
            onClose={closeFormSaveNotification}
            severity={alertStatus?.severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {alertStatus?.message}
          </Alert>
        </Snackbar>
        </>
    )
}