import {
    FunctionComponent,
    useContext,
} from "react";
import { UserContext } from "components/user";
import { PageLayout, Wrapper } from "components/layout";
import { UserNeedsAuthentication } from "components/user";
import { Text } from "components/text";
import { JiraBugCollector } from "components/jiraBugCollector";
import { Column, Row } from "components/structure";

const HelpPage: FunctionComponent = () => {
    const { authenticated } = useContext(UserContext);

    return (
        <PageLayout header footer>
            <Wrapper>
                {authenticated ? (
                    <Column style={{gap: "0.5em"}}>
                        <Row>
                            <Column>
                                <Text>
                                    If you need to create a new form template or edit an existing form,
                                    please contact <a href='mailto: lindsey.kuettner@contextualize.us.com'>Lindsey</a>.
                                </Text>
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <div><JiraBugCollector /></div>
                            </Column>
                        </Row>
                    </Column>
                ) : (
                    <UserNeedsAuthentication />
                )}
            </Wrapper>
        </PageLayout>
    );
};

export default HelpPage;
