import { FunctionComponent } from "react"
import { BugReport } from "@mui/icons-material";

interface BugCollectorProps{

}

const jiraBugUrl = "https://contextualize.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/0/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=0d098e18"

const Constants = {
    JIRA_ISSUE_COLLECTOR_SCRIPT: `<html>
    <head>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <Script
    type="text/javascript"
    src="${jiraBugUrl}">
    </script>
    <script type="text/javascript">window.ATL_JQ_PAGE_PROPS = {
    "triggerFunction": function (showCollectorDialog) {
    jQuery(document).ready(function () {
    setTimeout(function () {
    showCollectorDialog();
    }, 50);
    });
    }
    };</script>
    </head>
    <body>
    </body>
    </html>`,
  };

export const JiraBugCollector: FunctionComponent<BugCollectorProps> = ()=>{
    return (
        <button
        className="jira_issue_btn"
        onClick={() => {
          const blankWindow = window.open('about:blank');
          const document = blankWindow?.document;
          document?.open();
          document?.write(`${Constants.JIRA_ISSUE_COLLECTOR_SCRIPT}`);
          document?.close();
        }}
        style={{display: 'flex', flexDirection: "row", justifyContent: "center", alignItems:"center"}}
      >
        <BugReport /> <span>Report Bug</span>
        
      </button>)
}