import { Button, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, OutlinedInput, useMediaQuery, useTheme } from "@mui/material";
import { FunctionComponent, useMemo, useState } from "react";
import { minTableColumnWidth } from "./const";
import { Form } from "@rjsf/mui";
import { JSONSchema7 } from 'json-schema'
import validator from "@rjsf/validator-ajv8";
import { Edit } from "@mui/icons-material";

interface ArrayFieldProps {
    schema: JSONSchema7
    defaultValue?: any[];
    onChange: (value: any) => void;
    required: boolean;
}

const preventDefault = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
};

export const ArrayField: FunctionComponent<ArrayFieldProps> = ({
    defaultValue,
    required,
    onChange,
    schema
}) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [internalData, setInternalData] = useState(defaultValue ?? []);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        onChange(internalData);
      };

    const form = useMemo(()=>{
        return (<Form
            schema={schema}
            formData={internalData}
            liveValidate
            showErrorList={false}
            validator={validator}
            onChange={(value)=>{
                setInternalData(value.formData);
            }}
          ><></></Form>)
    }, [schema, internalData])

    return (
        <>
        <OutlinedInput
        style={{minWidth: minTableColumnWidth}}
        type='text'
        readOnly={true}
        value={`${defaultValue?.length ?? 0} Value(s)`}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Print barcode"
              onClick={handleClickOpen}
              onMouseDown={preventDefault}
              onMouseUp={preventDefault}
            >
              <Edit />
            </IconButton>
          </InputAdornment>
        }/>
        <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose} >
        <DialogContent>
            {form}
             
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
        </>
    )
}
