import { FunctionComponent } from "react";
import { Column, Row } from "components/structure";
import { Text } from "components/text";
import packageInfo from "../../../package.json";
import { JiraBugCollector } from "components/jiraBugCollector";

/** A component that displays a footer at the bottom of a page. */
const Footer: FunctionComponent = ({ children, ...props }) => {
  return (
    <footer className="footer" {...props}>
      <Row style={{alignItems: "center"}}>
        <Column>
          <Text color="muted" justify="left">
            Carta Collection v{packageInfo.version}
          </Text>
          <Text color="muted" justify="left">
            &copy; 2024 Contextualize, LLC
          </Text>
        </Column>
        <Column  style={{alignItems: "end"}}>
          <JiraBugCollector/>
        </Column>
      </Row>
    </footer>
  );
};

export default Footer;
