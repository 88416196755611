import { FormControl, MenuItem, Select } from "@mui/material";
import { FunctionComponent } from "react";
import { minTableColumnWidth } from "./const";

interface SelectFieldProps {
    defaultValue?: string;
    onChange: (value: string) => void;
    required: boolean;
    options: string[];
    label: string;
}

export const SelectField: FunctionComponent<SelectFieldProps> = ({
    defaultValue,
    required,
    onChange,
    options
}) => {

    return (
        <FormControl fullWidth>
        <Select
                style={{minWidth: minTableColumnWidth}}
            required={required}
            value={defaultValue ?? ""}
            onChange={(event) => onChange(event.target.value)}
        >
            {options.map((option, index) => {
                return (<MenuItem value={option} key={index}>{option}</MenuItem>)
            })}
        </Select>
        </FormControl>
    )
}
