
import { JSONSchema7 } from "json-schema";

export interface IFormProps {
  title?: string;
  key: string;
}

export interface IProjectReference {
  projectId: string;
}

export interface IConnectionReference {
  connectionId: string;
}

export interface IProjectConnection extends IProjectReference, IConnectionReference {}

export interface NodeRequest extends IConnectionReference {
  projectId?: string;
}

export interface IConnection extends IConnectionReference {
  name?: string;
  node: INode;
}

export interface IProjectProps extends IProjectReference {
  name?: string;
}

export interface INodeSimple {
  nativeId: {
    fileId?: string;
    bucketName?: string;
    prefix?: string;
    type?: string;
  };
}

export interface INode extends INodeSimple {
  name: string;
  type?: "intermediate" | "leaf" | "empty";
}

export interface ICollection {
  projectId: string;
  connectionId?: string;
  node?: INodeSimple;
  parentNode?: INodeSimple;
  key?: string;
}

/**
 * Intentially blank, as Native Ids are only useful to their respective provider (i.e. Google Drive, FormsDB)
 */
export interface NativeId {}

export interface ILabeledRecord {
  label: string;
  title: string;
  foreignKeys: string[];
  nativeStr: string;
  created: string;
  nativeId: NativeId;
}

export type FormData = {[key: string]: any}

export interface KeyInfo{
  foreignKeyPaths: string[][];
  primaryKeyPath: string[];
}

export interface PrimaryKey{
  nativeStr: string;
  nativeId: NativeId;
  label: string;
  title: string;
  created: string;
  foreignKeys: string[];
}

export interface PrimaryKeyWithDate{
  nativeStr: string;
  label: string;
  title: string;
  created: Date;
  foreignKeys: string[];
}

export interface ISavedForm{
  nativeId?: NativeId;
  data?: FormData;
}

export interface ICreateCollectionResponse{
  message: string;
  nativeId?: NativeId;
}

export interface IFormSchemaResponse {
  form_data: ISavedForm[] | null;
  key_info: KeyInfo;
  schema: ISchemaDefinition;
  schema_key: {
    id: string;
    version: number;
  };
  primaryKeys: PrimaryKeyWithDate[];
}

export interface ISchemaProperty {
  properties?: {
    [key: string]: ISchemaProperty;
  };
  items?: ISchemaProperty
  type: string;
  title?: string;
  propertyOrder?: number;
  required?: string[];
  $ref?: string;
  oneOf?: ISchemaProperty[]
}

export interface ISchemaDefinition extends ISchemaProperty{
  definitions?: {
    [key: string]: {
      format?: string;
      options?: {
        [key: string]: any;
      };
      type: string;
      description?: string;
      default?: any;
      minimum?: number;
    };
  };
  description?: string;
  properties: {
    [key: string]: ISchemaProperty;
  };
  title: string;
}

export function normalizeDefinitions(schema: JSONSchema7, definitions?: {[key: string]: JSONSchema7}){
  if(!definitions){
    definitions = schema.definitions as {[key: string]: JSONSchema7};
  }
  let value: JSONSchema7 | false = false;
  if(schema.$ref){
    const path = schema.$ref.split('/');
    if(path.length > 3){
      throw new Error("Deep reference not supported");
    }
    const refValue = definitions[path[2]]
    value = {...schema, ...refValue};
    delete value.$ref;
  }

  if(schema.properties){
    Object.entries(schema.properties).forEach(([key, property])=>{
      if(typeof property === 'boolean')
        return;

      const changed = normalizeDefinitions(property, definitions);
      if(changed){
        (schema.properties as {[key: string]: JSONSchema7})[key] = changed;
      }
    })
  }

  if(schema.items){
    if(Array.isArray(schema.items)){
      schema.items.forEach((item, index)=>{
        if(typeof item === 'boolean')
          return;

        const changed = normalizeDefinitions(item, definitions);
        if(changed){
          (schema.items as JSONSchema7[])[index] = changed;
        }
      })
    }else if(typeof schema.items !== 'boolean'){
      const changed = normalizeDefinitions(schema.items, definitions);
      if(changed){
        schema.items = changed;
      }
    }
  }

  return value;
}

